export default {
  notifications(state) {
    return state.notifications;
  },
  unreadCount(state) {
    return state.unreadCount;
  },
  unreadMes(state) {
    return state.unreadMes;
  },
};
