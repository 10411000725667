import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
import Home from "../views/Home.vue"; // Home

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start HomePage ==========

// ========== Start Orders ==========
import OrdersHome from "../views/children/Orders/Home.vue";
// All Orders
import Orders from "../views/children/Orders/ShowAll.vue";
// Single Order
import ShowOrder from "../views/children/Orders/Show.vue";
// ========== End Orders ==========

// ========== Start profile ==========
import ProfileHome from "../views/children/Profile/Home.vue";
import ProfileShow from "../views/children/Profile/AdminShowHome.vue";
import ProfileEdit from "../views/children/Profile/Edit.vue";
// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";

// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },

        // ========== Start Sizes ==========

        {
          path: "/brands",
          component: () => import("../views/children/brands/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllBrands",
              component: () => import("../views/children/brands/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddBrand",
              component: () => import("../views/children/brands/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditBrand",
              component: () => import("../views/children/brands/Edit.vue"),
              props: true,
            },
          ],
        },
        // offers
        {
          path: "/offers",
          component: () => import("../views/children/Offers/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllOffers",
              component: () => import("../views/children/Offers/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddOffer",
              component: () => import("../views/children/Offers/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditOffer",
              component: () => import("../views/children/Offers/Edit.vue"),
              props: true,
            },
          ],
        },

        // offers

        {
          path: "/notifications",
          component: () => import("../views/children/Notifications/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllNotifications",
              component: () =>
                import("../views/children/Notifications/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddNotification",
              component: () =>
                import("../views/children/Notifications/Add.vue"),
            },
            // Edit
            {
              path: "show/:id",
              name: "showNotification",
              component: () =>
                import("../views/children/Notifications/Show.vue"),
              props: true,
            },
          ],
        },
        // Coupons
        {
          path: "coupons",
          component: () => import("../views/children/Coupons/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCoupons",
              component: () => import("../views/children/Coupons/ShowAll.vue"),
            },
            // Add
            {
              path: "add",
              name: "AddCoupons",
              component: () => import("../views/children/Coupons/Add.vue"),
            },
            // Edit
            {
              path: "edit/:id",
              name: "EditCoupons",
              component: () => import("../views/children/Coupons/Edit.vue"),
              props: true,
            },
          ],
        },

        // ========== Start Orders ==========
        {
          path: "/orders",
          component: OrdersHome,
          children: [
            // All Orders
            {
              path: "all-orders",
              name: "Orders",
              component: Orders,
            },
            {
              path: "show/:id",
              name: "ShowOrder",
              component: ShowOrder,
              props: true,
            },
          ],
        },
        // ========== End Orders ==========

        // ========== Start profile ==========
        {
          path: "/profile",
          component: ProfileHome,
          children: [
            {
              path: "show",
              component: ProfileShow,
            },
            {
              path: "edit",
              component: ProfileEdit,
            },
          ],
        },
        // ========== End profile ==========
      ],
    },

    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, from, next) => {
  sessionStorage.setItem("from_route", from.fullPath);

  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else if (to.name == "Login" || to.name == "NotFound") {
    store.dispatch("sideNav_module/reseatBodyPadding");
    next();
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;
