<template>
  <div class="create_wrapper">
    <!-- Start Breadcrumb -->
    <Breadcrumb :items="items" />
    <!-- End Breadcrumb -->

    <!-- Start Main Loader -->
    <transition name="fadeInUp" mode="out-in" v-if="loaderPage">
      <MainLoader />
    </transition>
    <!-- End Main Loader -->

    <div class="custom_card" v-else>
      <div class="card-header">
        <h4 class="card-title">{{ $t("edit") }}</h4>
      </div>

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateFormUpdateProfile">
        <div class="container">
          <div class="row">
            <div class="col-12 py-0">
              <uplode-image
                placeHolder="الصورة الشخصية"
                @inputChanged="uplodeImg_1"
                :data_src="data.image.src"
              ></uplode-image>
            </div>
            <!-- Start:: Image -->

            <!-- Start:: Name -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="text"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.name"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.name") }}
                </label>
              </div>
            </div>
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.country") }}
                </label>
                <multiselect
                  v-model="data.country"
                  :options="countries"
                  label="name"
                  track-by="id"
                  placeholder=""
                  @input="data.country ? getCities() : cities = null, (data.city = null)"
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>

            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <label class="form-label">
                  {{ $t("forms.labels.city") }}
                </label>
                <multiselect
                  v-model="data.city"
                  :options="cities"
                  label="name"
                  track-by="id"
                  placeholder=""
                  :searchable="true"
                  :allow-empty="true"
                  :show-labels="false"
                ></multiselect>
              </div>
            </div>
            <!-- End:: Phone Code -->
            <!-- Start:: Phone -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="number"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.phone"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.phone") }}
                </label>
              </div>
            </div>
            <!-- End:: Phone -->

            <!-- START:: MAP -->
            <div class="col-lg-12">
              <div class="large-map">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label">
                      <input
                        type="number"
                        step="any"
                        class="form-control"
                        id="autocomplete_ar"
                        placeholder=""
                        v-model="coordinates_to_edit.lat"
                        @input="upDateMakers"
                      />
                      <!-- @input="getAddressAr"
                    v-model="coordinates_to_edit.address" -->
                      <label class="form-label">{{ $t("lat") }}</label>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="input_wrapper top_label">
                      <input
                        type="number"
                        step="any"
                        class="form-control"
                        id="autocomplete_ar"
                        placeholder=""
                        v-model="coordinates_to_edit.lng"
                        @input="upDateMakers"
                      />
                      <!-- @input="getAddressAr"
                    v-model="coordinates_to_edit.address" -->
                      <label class="form-label">{{ $t("lng") }}</label>
                    </div>
                  </div>
                </div>

                <GmapMap
                  ref="gMap"
                  :center="center"
                  :zoom="12"
                  map-type-id="terrain"
                  style="width: 100%; height: 600px;"
                >
                  <GmapMarker
                    :position="coordinates_to_edit"
                    :clickable="true"
                    :draggable="true"
                    @drag="updateCoordinates"
                    @click="clickMethod(m)"
                  />
                </GmapMap>
              </div>
            </div>
            <!-- END:: MAP -->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading1">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading1"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->

      <!-- ==== Start Form ==== -->
      <form @submit.prevent="validateFormUpdatePassword">
        <div class="container">
          <h5>تغير كلمه المرور</h5>
          <div class="row">
            <!-- Start:: Password -->
            <div class="col-lg-12 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="password"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.currentPassword"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.currentPassword") }}
                </label>
              </div>
            </div>
            <!-- End:: Password -->
            <!-- Start:: Password -->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="password"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.newPassword"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.newPassword") }}
                </label>
              </div>
            </div>
            <!-- End:: Password -->
            <!-- Start:: Password  Confirmation-->
            <div class="col-lg-6 py-0">
              <div class="input_wrapper top_label">
                <input
                  type="password"
                  class="form-control"
                  @input="helper_checkIfInputIsEmpty"
                  v-model.trim="data.password_confirmation"
                />
                <label for="name_input" class="form-label">
                  {{ $t("forms.labels.confirmPassword") }}
                </label>
              </div>
            </div>
            <!-- End:: Password  Confirmation-->
          </div>
        </div>

        <div class="buttons_wrapper">
          <button class="button_style_1" :disabled="btnIsLoading3">
            {{ $t("forms.submit") }}
            <span class="btn_loader" v-if="btnIsLoading3"></span>
          </button>
        </div>
      </form>
      <!-- ==== End Form ==== -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Update",

  // props: ['id'],

  data() {
    return {
      // ========== Main Loader
      loaderPage: false,
      model: "users",
      // ========== Breadcrumbs
      items: [
        {
          text: this.$t("breadcrumb.mainPage"),
          disabled: false,
          href: "/",
        },
        {
          text: this.$t("breadcrumb.users.edit"),
          disabled: true,
          href: "",
        },
      ],

      // ========== Loading
      btnIsLoading1: false,
      btnIsLoading2: false,
      btnIsLoading3: false,
      btnIsLoading4: false,

      // ========== create_data
      data: {
        image: {
          title: "",
          src: "",
        },
        imagePreview: null,
        name: null,
        phone: null,
        phone_code: null,
        country: null,
        city: null,
        email: null,
        gender: null,
        currentPassword: null,
        newPassword: null,
        password_confirmation: null,
        is_ban: false,
        is_active: true,
        is_admin_active_user: false,
        ban_reason: null,
        bio: null,
        verify_code: null,
      },
      // ========== Select Lists Data
      genders: [
        {
          id: "male",
          name: this.$t("male"),
        },
        {
          id: "female",
          name: this.$t("female"),
        },
      ],
      countries: [],

      cities: [],
      center: {
        lat: null,
        lng: null,
      },
      markers: [],
      coordinates_to_edit: { lat: 0, lng: 0, address: null },
      is_verifaction_send: false,
    };
  },

  methods: {
    updateCoordinates(location) {
      this.coordinates_to_edit.lat = location.latLng.lat();
      this.coordinates_to_edit.lng = location.latLng.lng();
    },

    clickMethod(m) {
      this.center = m.position;
    },
    // END:: UPDATE LOCATION

    // ============== Get User Location
    upDateMakers() {
      this.coordinates_to_edit = {
        lat: +this.coordinates_to_edit.lat,
        lng: +this.coordinates_to_edit.lng,
      };
      this.center = this.coordinates_to_edit;
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
          this.center = this.markers[0].position;
          this.coordinates_to_edit.lat = this.center.lat;
          this.coordinates_to_edit.lng = this.center.lng;
        });
      }
    },

    // START:: G-MAP GET ADDRESS
    getAddressAr() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      var searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          bounds.extend(place.geometry.location);
          place.geometry.location.lat();
          place.geometry.location.lng();
          place.formatted_address;
          self.coordinates_to_edit.address = place.formatted_address;
          self.center.lat = place.geometry.location.lat();
          self.center.lng = place.geometry.location.lng();
          self.coordinates_to_edit.lat = place.geometry.location.lat();
          self.coordinates_to_edit.lng = place.geometry.location.lng();
          self.markers[0].position.lat = place.geometry.location.lat();
          self.markers[0].position.lng = place.geometry.location.lat();
        });
      });
    },
    // ============ Get Main Data
    getData() {
      this.loaderPage = true;
      this.$axios({
        method: "GET",
        url: `profile`,
      }).then((res) => {
        this.loaderPage = false;
        this.data.image.src = res.data.data.avatar;
        this.data.name = res.data.data.full_name;
        this.data.phone = res.data.data.phone;

        this.data.country = res.data.data.country;
        this.getCities();
        this.center.lat = +res.data.data.lat;
        this.center.lng = +res.data.data.lng;
        this.coordinates_to_edit.lat = +res.data.data.lat;
        this.coordinates_to_edit.lng = +res.data.data.lng;
        this.data.city = res.data.data.city;
        this.loaderPage = false;
      });
    },

    getCities() {
      this.$axios({
        method: "GET",
        url: `country/${this.data?.country?.id}/cities`,
      }).then((res) => {
        this.cities = res.data.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
      });
    },
    // ============ Get Select Lists Data

    // Uplode Image
    uplodeImg_1(obj) {
      this.data.image.title = obj.img_file;
    },
    uplodeImg_qr(obj) {
      this.data.qr_image = obj;
    },
    validateFormUpdateProfile() {
      this.btnIsLoading = true;
      if (!this.data.image) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.image"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.name) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.name"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.phone) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.phone"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.country) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.country"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else if (!this.data.city) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.city"),
          position: "bottomRight",
        });
        this.btnIsLoading = false;
        return;
      } else {
        this.submitDataProfileUpdate();
        return;
      }
    },

    // Validate update profile

    // Submit update profile
    submitDataProfileUpdate() {
      const submit_data = new FormData();
      // submit_data.append("_method", "PUT");
      if (this.data.image.title) {
        submit_data.append("avatar", this.data.image.title);
      }
      submit_data.append("full_name", this.data.name);

      submit_data.append("phone", this.data.phone);
      submit_data.append("phone_code", this.data.country.phone_code);
      if(this.data.country?.id){
        submit_data.append("country_id", this.data.country?.id);
        submit_data.append("city_id", this.data.city?.id);
      }

      this.$axios({
        method: "POST",
        url: `profile`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/users" });
          this.btnIsLoading1 = false;
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading1 = false;
        });
    },

    sendVerficationCode() {
      if (!this.data.verify_code) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.verfication_code"),
          position: "bottomRight",
        });
        return;
      }
      this.btnIsLoading4 = true;
      const submit_data = new FormData();
      submit_data.append("_method", "PUT");
      submit_data.append("phone", this.data.phone);
      submit_data.append("phone_code", this.data.phone_code.phone_code);
      submit_data.append("code", this.data.verify_code);
      this.$axios({
        method: "POST",
        url: `update-phone`,
        data: submit_data,
      })
        .then((res) => {
          this.$iziToast.success({
            timeout: 2000,
            message: res.data.message,
            position: "bottomRight",
          });
          // this.$router.push({ path: "/users" });
          this.btnIsLoading4 = false;
          this.is_verifaction_send = false;
          this.getData();
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading4 = false;
        });
    },
    // Validate update password
    validateFormUpdatePassword() {
      this.btnIsLoading3 = true;

      if (!this.data.currentPassword) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("forms.validation.currentPassword"),
          position: "bottomRight",
        });
        this.btnIsLoading3 = false;
        return;
      } else if (!this.data.newPassword) {
        this.$iziToast.error({
          timeout: 2000,
          message: "ادخل كلمه المرور الجديده",
          position: "bottomRight",
        });
        this.btnIsLoading3 = false;
        return;
      } else if (this.data.newPassword != this.data.password_confirmation) {
        this.$iziToast.error({
          timeout: 2000,
          message: "كلمه المرور غير متوافقه",
          position: "bottomRight",
        });
        this.btnIsLoading3 = false;
        return;
      } else {
        this.submitDataPasswordUpdate();
        return;
      }
    },
    // submit profile update
    submitDataPasswordUpdate() {
      const submit_data = new FormData();
      // submit_data.append("_method", "PUT");
      submit_data.append("old_password", this.data.currentPassword);
      submit_data.append("password", this.data.newPassword);
      // submit_data.append('phone_code', this.data.phone_code.phoneCode)
      submit_data.append(
        "new_password_confirmation",
        this.data.password_confirmation
      );
      // submit_data.append('gender', this.data.gender.id)
      this.$axios({
        method: "POST",
        url: `edit_password`,
        data: submit_data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("editSuccess"),
            position: "bottomRight",
          });
          // this.$router.push({ path: "/users" });
          this.btnIsLoading3 = false;
          this.data.currentPassword = "";
          this.data.newPassword = "";
          this.data.password_confirmation = "";
        })
        .catch((err) => {
          this.$iziToast.error({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
          this.btnIsLoading3 = false;
        });
    },

    async getCountries() {
      const res = await this.$axios({
        method: "GET",
        url: `countries`,
      });

      this.countries = res.data.data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          phone_code: item.phone_code,
        };
      });
    },
    // getData() {
    //   this.data.loading = true;
    //   this.$axios({
    //     method: "GET",
    //     url: `profile`,
    //   })
    //     .then((res) => {
    //       this.data.userInfo = res.data.data;
    //       this.data.userInfo.loading = false;
    //       console.log(this.data.userInfo);
    //       this.data.loading = false;
    //     })
    //     .catch((err) => {
    //       this.$iziToast.error({
    //         timeout: 2000,
    //         message: err.response.data.message,
    //         position: "bottomRight",
    //       });
    //       this.data.loading = false;
    //     });
    // },
  },

  async created() {
    // Start:: Fire Methods
    await this.getCountries();
    this.getData();
    // End:: Fire Methods
  },
};
</script>
